import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { PageHeader } from 'antd';
import { HomeOutlined } from '@ant-design/icons';
import { routes } from '../../utils/constants/adminRoutes';

export const PageHeaderCustom = ({ title, extra }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  const splitPathname = pathname.split('/').filter((p) => p !== '');
  let tmpPath;
  const breadCrumbs = splitPathname
    .slice(0, splitPathname.length - 1)
    .map((p) => {
      tmpPath = `/${p}`;
      return {
        path: tmpPath,
        breadcrumbName: t(`breadcrumbs.${p}`)
      };
    });
  const dynamicRoutes = [
    {
      path: routes.HOME,
      breadcrumbName: <HomeOutlined />
    },
    ...breadCrumbs
  ];

  const itemRender = (
    // eslint-disable-next-line react/prop-types
    { path, breadcrumbName, state, search, ...route },
    params,
    routesList
  ) => {
    const last = routesList.indexOf(route) === routesList.length - 1;
    return last ? (
      <span key={path}>{breadcrumbName}</span>
    ) : (
      <Link key={path} to={{ pathname: path, state, search }}>
        {breadcrumbName}
      </Link>
    );
  };

  return (
    <PageHeader
      breadcrumb={{ routes: dynamicRoutes, itemRender }}
      title={title}
      onBack={() => navigate(-1)}
      ghost={false}
      extra={extra}
    />
  );
};

PageHeaderCustom.propTypes = {
  title: PropTypes.string.isRequired,
  extra: PropTypes.oneOfType([PropTypes.element, PropTypes.bool])
};

PageHeaderCustom.defaultProps = {
  extra: null
};
