import React from 'react';
import {
  HomeOutlined,
  UserOutlined,
  CoffeeOutlined,
  ThunderboltOutlined,
  EnvironmentOutlined,
  HistoryOutlined,
  ClusterOutlined,
  ExperimentOutlined,
  AlertOutlined,
  ImportOutlined
} from '@ant-design/icons';

const navMenuLogos = {
  HOME: <HomeOutlined />,
  USERS: <UserOutlined />,
  DR: <EnvironmentOutlined />,
  SOURCEPOSTS: <ThunderboltOutlined />,
  D12: <ClusterOutlined />,
  RESEARCH: <ExperimentOutlined />,
  RESCUE: <AlertOutlined />,
  IMPORT: <ImportOutlined />,
  HISTORY: <HistoryOutlined />,
  NEWS: <CoffeeOutlined />
};

export default navMenuLogos;
