import React, { useCallback, useEffect, useState } from 'react';
import { DatePicker, Select, Tag, Input, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/ErrorMessage';
import { userRoles } from '../../utils/constants/tagColors';

const { Option } = Select;

const useFields = () => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [enums, setEnums] = useState({});

  const fields = [
    {
      name: ['last_name'],
      rules: [{ required: false }]
    },
    {
      name: ['first_name'],
      rules: [{ required: false }]
    },
    {
      name: ['role'],
      required: false,
      input: (
        <Select loading={isLoading}>
          {(enums.roles || []).map((role) => (
            <Option key={role} value={role}>
              <Tag color={userRoles[role.split(':')[1]]}>
                {t(`users.tags.${role.split(':')[1]}`)}
              </Tag>
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['permission'],
      required: true,
      input: (
        <Select loading={isLoading}>
          {(enums.roles || []).map((role) => (
            <Option key={role} value={role}>
              <Tag color={userRoles[role.split(':')[1]]}>
                {role.split(':')[1]}
              </Tag>
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['email'],
      rules: [{ required: false }]
    },
    {
      name: ['phone_number'],
      label: 'phone_number.number',
      input: (
        <Input.Group compact>
          <Form.Item
            noStyle
            name={['phone_number', 'country_code']}
            initialValue="+33"
          >
            <Select style={{ width: '25%' }}>
              <Option value="+33">+33</Option>
            </Select>
          </Form.Item>
          <Form.Item noStyle name={['phone_number', 'number']}>
            <Input style={{ width: '75%' }} />
          </Form.Item>
        </Input.Group>
      )
    },
    {
      name: ['date_of_birth'],
      input: <DatePicker />
    },
    {
      label: 'address_number',
      name: ['address', 'number']
    },
    {
      label: 'address_street',
      name: ['address', 'street']
    },
    {
      label: 'address_additional',
      name: ['address', 'additional']
    },
    {
      label: 'address_postcode',
      name: ['address', 'postal_code']
    },
    {
      label: 'address_city',
      name: ['address', 'city']
    }
  ];

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/users/enums' });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsLoading(true);
    await getEnums();
    setIsLoading(false);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  return {
    fields
  };
};

export default useFields;
