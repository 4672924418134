import React from 'react';
import {
  ThunderboltOutlined,
  ColumnHeightOutlined,
  SplitCellsOutlined,
  DatabaseOutlined,
  HomeOutlined
} from '@ant-design/icons';

export const disabledButton = {
  NO: ['RA', 'DE'],
  TR: ['RA', 'DE'],
  CO: ['DE'],
  RA: ['RA'],
  DE: ['RA', 'DE']
};

export const selectedButton = {
  TR: 2,
  CO: 3,
  RA: 4,
  DE: 1
};

export const currentTitle = {
  TR: 'CO',
  CO: 'RA',
  RA: 'DE',
  DE: 'TR'
};

export const stepButtons = [
  {
    icon: <ThunderboltOutlined />,
    title: 'TR',
    key: 1
  },
  {
    icon: <ColumnHeightOutlined />,
    title: 'CO',
    key: 2
  },
  {
    icon: <DatabaseOutlined />,
    title: 'RA',
    key: 3
  },
  {
    icon: <HomeOutlined />,
    title: 'DE',
    key: 4
  },
  {
    icon: <SplitCellsOutlined />,
    title: 'AT',
    key: 5
  }
];

export const styles = {
  colorTag: {
    display: 'inline-block',
    width: 20,
    height: 20,
    margin: 2
  }
};

export const colorsArray = [
  '#B5DC6F',
  '#6FB1E7',
  '#4BC3C3',
  '#FFC328',
  '#FF4128'
];

export const modelName = {
  SP: 'Sourcepost',
  TR: 'Transformer',
  CO: 'Colonne',
  RA: 'Demirame',
  DE: 'Depart',
  RE: 'Rescue',
  RS: 'Research',
  D1: 'D12',
  PR: 'Project'
};

export const elementLink = {
  TR: 'transformer',
  CO: 'colonne',
  RA: 'demirame',
  DE: 'depart'
};
