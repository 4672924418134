import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Avatar, Button, Divider, Menu, message, Typography } from 'antd';
import { NavLink, useLocation } from 'react-router-dom';
import { UserOutlined, LogoutOutlined } from '@ant-design/icons';
import navMenuLogos from '../../utils/constants/navMenuLogos';
import { useHandleResize } from '../../utils/HandleResize';
import {
  routes,
  subRoutes,
  pathSearches
} from '../../utils/constants/adminRoutes';
import { useAuthContext } from '../../contexts/AuthContext';

const { Text } = Typography;
const { SubMenu } = Menu;

export const AdminNavMenu = ({ setCollapsed }) => {
  const { user, dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const location = useLocation();
  const { width } = useHandleResize();
  const path = location?.pathname || '/';
  const activeKeys = path
    .replace('/', '')
    .split('/')
    .map((p) => `/${p}`);

  const logout = () => {
    dispatchAPI('LOGOUT');
    message.success(t('login.logoutSuccess'));
  };

  return (
    <div className="menu-section">
      <Menu
        className="menu-list"
        selectedKeys={activeKeys}
        defaultOpenKeys={activeKeys}
        mode="inline"
        onSelect={width < 992 ? () => setCollapsed(true) : () => {}}
      >
        {Object.entries(routes).map(([menuItem, p]) =>
          subRoutes[menuItem] ? (
            <SubMenu
              key={p}
              icon={navMenuLogos[menuItem]}
              title={t(`basiclayout.menu.${menuItem.toLowerCase()}`)}
            >
              {Object.entries(subRoutes[menuItem]).map(
                ([subMenuItem, subPath]) => (
                  <Menu.Item key={subPath} disabled={subPath === ''}>
                    <NavLink
                      to={`${p}${subPath}${
                        pathSearches[subMenuItem]
                          ? pathSearches[subMenuItem]
                          : ''
                      }`}
                    >
                      <span>
                        {t(`basiclayout.submenu.${subMenuItem.toLowerCase()}`)}
                      </span>
                    </NavLink>
                  </Menu.Item>
                )
              )}
            </SubMenu>
          ) : (
            <Menu.Item key={p} disabled={p === ''}>
              <NavLink
                to={`${p}${
                  pathSearches[menuItem] ? pathSearches[menuItem] : ''
                }`}
              >
                {navMenuLogos[menuItem]}
                <span>{t(`basiclayout.menu.${menuItem.toLowerCase()}`)}</span>
              </NavLink>
            </Menu.Item>
          )
        )}
      </Menu>
      <div className="bottom-part">
        <div className="divider">
          <Divider />
        </div>
        <div className="user-profile">
          {window.innerWidth <= 992 ? (
            <Avatar
              size={40}
              icon={user?.photo ? '' : <UserOutlined />}
              src={user?.photo ? user.photo : ''}
            >
              {`${user?.first_name[0]}${user?.last_name[0]}`}
            </Avatar>
          ) : (
            <>
              <Avatar
                size={40}
                icon={user?.photo ? '' : <UserOutlined />}
                src={user?.photo ? user.photo : ''}
              >
                {`${user?.first_name[0]}${user?.last_name[0]}`}
              </Avatar>
              <Button type="link">
                <Text className="name">{`${user?.first_name} ${user?.last_name}`}</Text>
                <Text>{t(`users.tags.${user?.role.split(':')[1]}`)}</Text>
              </Button>
              <LogoutOutlined style={{ fontSize: 16 }} onClick={logout} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

AdminNavMenu.propTypes = {
  setCollapsed: PropTypes.func.isRequired
};
