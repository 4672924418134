import React, { useEffect } from 'react';
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  useLocation
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { outOfNavRoutes, routes } from '../utils/constants/adminRoutes';
import { Login } from '../routes/login';
import { Home } from '../routes/home/Home';
import { Exception } from '../components';
import { useAuthContext } from '../contexts/AuthContext';
import { AdminLayout } from '../components/layouts/AdminLayout';
import { ProfileRouter } from '../routes/profile/ProfileRouter';
import { Confirmation } from '../routes/emailConfirmation/Confirmation';
import { Settings } from '../routes/settings/settings';
import { UserTheme } from '../assets/styles/Theme/UserTheme';
import { UserAdminRouter } from '../routes/users/UserAdminRouter';
import { ArticleRouter } from '../routes/blog/ArticleRouter';
import { DRRouter } from '../routes/DR/DRRouter';
import { SourcePostRouter } from '../routes/sourcePost/SourcePostRouter';
import { D12Router } from '../routes/D12/D12Router';
import { ResearchRouter } from '../routes/research/ResearchRouter';
import { RescueRouter } from '../routes/rescue/RescueRouter';
import { ImportRouter } from '../routes/import/ImportRouter';
import { HistoryRouter } from '../routes/history/HistoryRouter';

const RequireAuth = () => {
  const { isValid } = useAuthContext();
  const location = useLocation();

  if (!isValid) {
    return Navigate({
      to: {
        pathname: outOfNavRoutes.LOGIN
      },
      state: { from: location }
    });
  }

  return <AdminLayout />;
};

RequireAuth.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    state: PropTypes.shape({
      type: PropTypes.string,
      model: PropTypes.shape({
        name: PropTypes.string
      })
    })
  })
};

RequireAuth.defaultProps = {
  location: { pathname: '/' }
};

export const AdminRouter = () => {
  useEffect(() => {
    const body = document.body;
    if (body) {
      body.className = 'user';
    }
  });

  return (
    <BrowserRouter>
      <UserTheme />
      <Routes>
        <Route path={`${outOfNavRoutes.LOGIN}/:token`} element={<Login />} />
        <Route path={outOfNavRoutes.LOGIN} element={<Login />} />
        <Route
          path={`${outOfNavRoutes.CONFIRMATION}/:token`}
          element={<Confirmation />}
        />
        <Route element={<RequireAuth />}>
          <Route
            path={`${outOfNavRoutes.PROFILE}/*`}
            element={<ProfileRouter />}
          />
          <Route path={outOfNavRoutes.SETTINGS} element={<Settings />} />
          <Route path={routes.HOME} element={<Home />} />
          <Route path={`${routes.USERS}/*`} element={<UserAdminRouter />} />
          <Route path={`${routes.DR}/*`} element={<DRRouter />} />
          <Route
            path={`${routes.SOURCEPOSTS}/*`}
            element={<SourcePostRouter />}
          />
          <Route path={`${routes.D12}/*`} element={<D12Router />} />
          <Route path={`${routes.RESEARCH}/*`} element={<ResearchRouter />} />
          <Route path={`${routes.RESCUE}/*`} element={<RescueRouter />} />
          <Route path={`${routes.IMPORT}/*`} element={<ImportRouter />} />
          <Route path={`${routes.HISTORY}/*`} element={<HistoryRouter />} />
          <Route path={`${routes.NEWS}/*`} element={<ArticleRouter />} />
          <Route path="*" element={<Exception />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
