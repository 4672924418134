import { createGlobalStyle } from 'styled-components';
import '../SCSS/user/index.scss';

export const UserTheme = createGlobalStyle`
  body {
    --clientColor: #1423DC;
    --primaryColor: #1423DC;
    --addColor: #47d267;
    --itemHoverColor: var(--primaryColor);
    --componentBackground: #fff;
    --secondaryHover: var(--componentBackground);
    --subMenuBackground: var(--componentBackground);
    --menuDropdownBackground: var(--componentBackground);
    --pickerDropdown: var(--componentBackground);
    --primaryHover:  #F6F6FE;
    --secondaryColor: #ff4d4f;
    --disabledColor: #bfbfbf;
    --bodyBackground: #f0f2f5;
    --textColor: rgba(0, 0, 0, 0.65);
    --textHolder: #678197;
    --textColorSecondary: rgba(0, 0, 0, 0.45);
    --itemActiveBackground: #e6f7ff;
    --itemHoverBackground: #f0f0f0;
    --borderColor: #d9d9d9;
    --contrastBackground: #f5f5f5;
    --opacitySVG: 1;
    --primaryColorA04;
    --primaryColorA07;
    --primaryContrast;
    --tabsGray;
    --textColorInvert;
    --textColorHover;
    --textColorA015;
    --textColorA075;
    --borderColorA06;
    --disabledColorA04;
    --disabledColorA06;
    --itemActiveColor;
    --logo;
    --logoSmall;
    --errorColor;
    --error100;
    --errorA08;
    --textBlack: #101014;
    --textWhite: #ffffff;
    --blueEnedis200: #F6F6FE;
    --blueEnedis300: #DADDFC;
    --blueEnedis400: #4D59EF;
    --blueEnedis500: #1423DC;
    --blueEnedis600: #0F1AA4;
    --blueEnedis700: #070D4F;
    --greenEnedis200: #F7FBEF;
    --greenEnedis300: #D5EBAD;
    --greenEnedis400: #B5DC6F;
    --greenEnedis500: #96CD32;
    --greenEnedis600: #698F23;
    --greenEnedis700: #3C5214;
    --grey50: #ffffff;
    --grey75: #fafafa;
    --grey100: #f1f2f3;
    --grey200: #E9EBEC;
    --grey300: #DEE0E3;
    --grey400: #C8CBD0;
    --grey500: #AEB1B7;
    --grey600: #868A93;
    --grey700: #60616C;
    --grey800: #3B3C45;
    --grey900: #101014;
    --greyMuted: #F5F5F5;
  }
`;
