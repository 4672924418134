export const userRoles = {
  GUEST: 'green',
  USER: 'blue',
  'SUPER-USER': 'geekblue',
  ADMIN: 'red',
  'SUPER-ADMIN': 'magenta'
};

export const userStatus = {
  ACTIVE: 'green',
  ARCHIVED: 'red'
};

export const pendingTasksCat = {
  POST: 'green',
  PATCH: 'blue',
  DELETE: 'red'
};

export const projectStatus = {
  YES: 'green',
  NO: 'red',
  IN_PROGRESS: 'blue',
  ARCHIVED: 'orange',
  SIGNED: 'green'
};

export const historicDotType = {
  PATCH: 'blue',
  CREATE: 'green',
  DELETE: 'red'
};

export const colorsRD = [
  { name: 'Orange', value: '#ff7a45' },
  { name: 'Rouge', value: '#ff4d4f' },
  { name: 'Jeaune', value: '#ffd666' },
  { name: 'Vert', value: '#95de64' },
  { name: 'Cyan', value: '#5cdbd3' },
  { name: 'Bleu', value: '#69c0ff' },
  { name: 'Violet', value: '#b37feb' },
  { name: 'Rose', value: '#ff85c0' },
  { name: 'Volcano', value: '#ff9c6e' },
  { name: 'Gold', value: '#ffd700' },
  { name: 'Lime', value: '#a9d70b' },
  { name: 'Geek Bleu', value: '#2f54eb' },
  { name: 'Magenta', value: '#eb2f96' },
  { name: 'Gris', value: '#bfbfbf' },
  { name: 'Noir', value: '#000000' },
  { name: 'Turquoise', value: '#40E0D0' }
];
