import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import moment from 'moment';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Pagination, Popconfirm, Card, Row, Col, Spin } from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/ErrorMessage';
import BgImage from '../../assets/images/alexis-antoine-m1s1SDdbCQY-unsplash.jpg';

const iconSize = 18;
const { Meta } = Card;

export const DataCard = ({
  resourceName,
  path,
  dataKeys,
  populate,
  extraQuery,
  forceRefresh,
  showAction,
  showTitle,
  editAction,
  deleteAction,
  rowKey,
  isAdmin,
  formattedData,
  numberOfCards,
  showCover
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [isLoading, setIsLoading] = useState(false);
  const [resources, setResources] = useState([]);
  const params = new URLSearchParams(location.search);
  const searchValue = params.get('k');
  const currentPage = Number(params.get('p') || 1);
  const pageSize = Number(params.get('pS') || 10);
  const currentFilters = params.get('f');
  const currentSorter = params.get('s');
  let bigTotal = 0;
  const [pagination, setPagination] = useState({
    pageSize: 10,
    total: 0,
    showTotal: (total) =>
      bigTotal ? `${total} sur ${bigTotal} éléments` : `${total} éléments`,
    showSizeChanger: true
  });

  const formattedContent = (text) => {
    const pattern = /<.*?>/g;
    return pattern.test(text) ? parse(text) : text;
  };

  const isDateFormat = (text) => {
    const regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
    return regex.test(text);
  };

  const showTotal = () =>
    bigTotal
      ? `${pagination.total} sur ${bigTotal} éléments`
      : `${pagination.total} éléments`;

  const fetchData = useCallback(
    async (page = pagination) => {
      setIsLoading(true);

      const searchURL = searchValue ? `/search/${searchValue}` : null;

      let sortingParameter;
      if (currentSorter) sortingParameter = `sort=${currentSorter}&`;
      let filterParameter;
      if (currentFilters)
        filterParameter = `${currentFilters.replaceAll('__', '&')}`;

      try {
        const { data, headers } = await dispatchAPI('GET', {
          url: `/${resourceName}${searchURL || ''}?${
            extraQuery ? `${extraQuery}&` : ''
          }${sortingParameter || ''}${filterParameter || ''}${
            populate ? `populate=${populate}&` : ''
          }limit=${pageSize}&skip=${(currentPage - 1) * pageSize}`
        });
        setPagination({
          ...page,
          total: parseInt(headers?.['x-total-count'] || 0, 10)
        });
        if (headers['x-total-result'])
          bigTotal = parseInt(headers['x-total-result'], 10);
        setResources(
          formattedData ? (data.length && data[0][formattedData]) || [] : data
        );
      } catch (e) {
        message(e);
      }
      setIsLoading(false);
    },
    [
      searchValue,
      currentPage,
      pageSize,
      currentSorter,
      currentFilters,
      forceRefresh,
      extraQuery
    ]
  );

  const deleteResource = async (id) => {
    try {
      await dispatchAPI('DELETE', { url: `/${resourceName}/${id}` });
      await fetchData();
    } catch (e) {
      message(e);
    }
  };

  const handlePageChange = (page, filters, sorters = {}) => {
    let sortingParameter;
    if (sorters) {
      if (!sorters.order) {
        sortingParameter = null;
      } else if (sorters.order === 'descend') {
        sortingParameter = `&s=-${sorters.columnKey}`;
      } else {
        sortingParameter = `&s=${sorters.columnKey}`;
      }
    }
    let filterParameter = '';
    Object.entries(filters || {}).forEach((el) => {
      if (el[1] && el[1].length) filterParameter += `${el[0]}=${[...el[1]]}__`;
    });
    filterParameter = filters;
    navigate({
      pathname,
      search: `?p=${page}${sortingParameter || ''}${
        filters ? `&pS=${filters}` : ''
      }${filterParameter ? `&f=${filterParameter}` : ''}${
        searchValue ? `&k=${searchValue}` : ''
      }`
    });
  };

  useEffect(() => {
    (async () => {
      await fetchData();
    })();
  }, [fetchData]);

  const getActionCard = (resource) => {
    const actionCard = [];
    if (showAction)
      actionCard.push(
        <Link
          to={{
            pathname: showAction.pathname
              ? showAction.pathname(resource)
              : `${path || pathname}/show/${resource?.[rowKey]}`
          }}
        >
          <EyeOutlined
            style={{ fontSize: iconSize, color: 'var(--primaryColor)' }}
          />
        </Link>
      );
    if (editAction && isAdmin)
      actionCard.push(
        <Link
          to={{
            pathname: editAction.pathname
              ? editAction.pathname(resource)
              : `${path || pathname}/edit/${resource?.[rowKey]}`
          }}
        >
          <EditOutlined
            style={{ fontSize: iconSize, color: 'var(--primaryColor)' }}
          />
        </Link>
      );
    if (deleteAction && isAdmin)
      actionCard.push(
        <Popconfirm
          title={t('datatable.column.action.delete.title')}
          okText={t('datatable.column.action.delete.ok')}
          okButtonProps={{ type: 'danger' }}
          cancelText={t('datatable.column.action.delete.cancel')}
          onConfirm={() => deleteResource(resource?.[rowKey])}
          icon={<WarningOutlined />}
        >
          <DeleteOutlined
            style={{ color: '#b51010', fontSize: iconSize }}
            type="delete"
          />
        </Popconfirm>
      );
    return actionCard;
  };

  return isLoading ? (
    <Spin
      spinning={isLoading}
      size="large"
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%'
      }}
    />
  ) : (
    <>
      <Row>
        {(resources || [])
          .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
          .map((resource) => (
            <Col span={Math.round(24 / numberOfCards) || 8} key={resource._id}>
              <Card
                key={resource._id}
                hoverable
                onDoubleClick={() =>
                  navigate(`${path || pathname}/show/${resource[rowKey]}`)
                }
                title={
                  showTitle && (
                    <h3 className="card-title">
                      {resource[dataKeys.card.title]}
                    </h3>
                  )
                }
                style={{
                  width: '80%',
                  marginBottom: 15
                }}
                actions={getActionCard(resource)}
                bordered={false}
                cover={
                  showCover && (
                    <img
                      alt={resource[dataKeys.card.alt]}
                      src={resource[dataKeys.card.src] || BgImage}
                    />
                  )
                }
              >
                <Meta
                  title={
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between'
                      }}
                    >
                      <h3 className="card-title">
                        {resource[dataKeys.meta.title]}
                      </h3>
                      {dataKeys?.meta?.extraTitle &&
                      isDateFormat(resource[dataKeys?.meta?.extraTitle]) ? (
                        <p style={{ fontSize: 12 }}>
                          {moment(resource[dataKeys?.meta?.extraTitle]).format(
                            'DD.MM.YYYY'
                          )}
                        </p>
                      ) : (
                        <p>{resource[dataKeys?.meta?.extraTitle]}</p>
                      )}
                    </div>
                  }
                  description={
                    <p className="card-text-description">
                      {resource[dataKeys.meta.description]}
                    </p>
                  }
                />
                <p className="card-text-content">
                  {formattedContent(resource[dataKeys.meta.content])}
                </p>
              </Card>
            </Col>
          ))}
      </Row>
      <Row justify="end">
        <Pagination
          total={pagination.total}
          current={currentPage}
          pageSize={pageSize}
          onChange={handlePageChange}
          showSizeChanger
          showTotal={showTotal}
        />
      </Row>
    </>
  );
};

DataCard.propTypes = {
  resourceName: PropTypes.string.isRequired,
  path: PropTypes.string,
  dataKeys: PropTypes.shape({
    card: PropTypes.shape({
      title: PropTypes.string,
      extraTitle: PropTypes.string,
      alt: PropTypes.string,
      src: PropTypes.string
    }),
    meta: PropTypes.shape({
      title: PropTypes.string,
      extraTitle: PropTypes.string,
      description: PropTypes.string,
      content: PropTypes.string
    })
  }),
  populate: PropTypes.string,
  extraQuery: PropTypes.string,
  forceRefresh: PropTypes.bool,
  editAction: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      pathname: PropTypes.func
    })
  ]),
  showAction: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      pathname: PropTypes.func
    })
  ]),
  showTitle: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      pathname: PropTypes.func
    })
  ]),
  showCover: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      pathname: PropTypes.func
    })
  ]),
  deleteAction: PropTypes.bool,
  rowKey: PropTypes.string,
  isAdmin: PropTypes.bool,
  numberOfCards: PropTypes.number,
  formattedData: PropTypes.string
};

DataCard.defaultProps = {
  path: null,
  dataKeys: {},
  populate: null,
  extraQuery: null,
  forceRefresh: null,
  editAction: true,
  showAction: true,
  showTitle: true,
  showCover: true,
  deleteAction: true,
  rowKey: '_id',
  isAdmin: null,
  numberOfCards: undefined,
  formattedData: null
};
