// Main nav menuItems
export const routes = {
  HOME: '/',
  USERS: '/users',
  SOURCEPOSTS: '/sourceposts',
  HISTORY: '/history',
  NEWS: '/news'
};

// Main nav subMenuItems
export const subRoutes = {
  PROJECTS: '/projects',
  WEATHER: '/weather',
  PRODUCER: '/producer',
  CONTRACTUALRELIEF: '/contractualrelief',
  TRANSFORMER: '/transformer',
  COLONNE: '/colonne',
  DEMIRAME: '/demirame',
  DEPART: 'depart'
};

// Other routes
export const outOfNavRoutes = {
  LOGIN: '/login',
  CONFIRMATION: '/email-confirmation',
  PROFILE: '/profile',
  SETTINGS: '/settings'
};

// url search parameters
export const pathSearches = {
  D12: '?s=-created_at'
};
